import React from 'react';
import styled from 'styled-components';
import tiger from '../../assets/images/ranthambore/tiger.jpg';
import tigerstamp from '../../assets/images/ranthambore/tigerstamp.png';
import ranthambore2 from '../../assets/images/ranthambore/ranthambore2.jpg';
import travelline from '../../assets/images/travel_line_2.svg';
import noise from '../../assets/images/noise2.png';
import leavenotrace from '../../assets/images/leavenotrace.svg';
import leavenotracestar from '../../assets/images/leavenotracestar.svg';
import Button from '../button';
// home images

const RanthamboreTigers = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    flex-direction: row;
  }
  .left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 5vw 25vw 5vw;
    @media (min-width: 960px) {
      padding: 0 30px 0 30px;
    }
    .left-wrap {
      max-width: 500px;
      position: relative;
      .tigerstamp {
        z-index: 0;
        position: absolute;
        left: -120px;
        top: -80px;
        background: url(${tigerstamp});
        background-size: cover;
        width: 200px;
        height: 200px;
        opacity: 0.46;
      }
    }
    .intro-text {
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    .text {
      margin-bottom: 30px;
      position: relative;
      line-height: 1.2;
    }
    .tg {
      border-collapse: collapse;
      border-spacing: 0;
      vertical-align: middle;
      padding: 10px 15px;
    }
    .tg td {
      padding: 5px;
      border-color: black;
      border-style: solid;
      border-width: 1px;

      font-size: 14px;
      overflow: hidden;
      padding: 10px 5px;
      word-break: normal;
    }
    .tg th {
      padding: 5px;
      border-color: black;
      border-style: solid;
      border-width: 1px;

      font-size: 14px;
      font-weight: normal;
      overflow: hidden;
      padding: 10px 5px;
      word-break: normal;
    }
    .tg .tg-0lax {
      padding: 10px;
      text-align: left;
      vertical-align: middle;
      &.last {
        background: var(--earth);
        color: var(--beige);
        vertical-align: middle;
        text-transform: uppercase;
        padding: 20px;
        a {
          text-decoration: none;
        }
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const FullPage = styled.section`
  width: 100vw;
  height: 50vh;
  @media (min-width: 768px) {
    height: 100vh;
  }
  background-size: cover !important;
  background-position: center center !important;
  background: url(${ranthambore2});
  margin-top: 200px;
  background-repeat: no-repeat;
`;

const FullPage2 = styled.section`
  width: 100vw;
  min-height: 50vh;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  .leave-no-trace {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 200px;
    position: relative;
    .spinner {
      width: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .circle-text {
        width: 100px;
        height: 100px;
        animation: spin 60s infinite linear;
        max-width: none;
      }
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
    .mfstar {
      width: 30px;
    }
  }
  .text {
    font-size: 1.2em;
    max-width: 450px;
    color: var(--beige);
    p {
      line-height: 1.7;
    }
  }
  @media (min-width: 768px) {
    min-height: 100vh;
  }

  background-image: url(${noise});
  background-color: var(--earth);
  background-position: right;
`;

export default function RanthamboreSection2() {
  return (
    <>
      <RanthamboreTigers>
        <div className="left">
          <div className="left-wrap">
            <div className="tigerstamp" />
            <div className="intro-text b">Ranthambore Jungle Safari</div>
            <div className="text">
              <p>
                {' '}
                Nahargarh is magnificently poised near the forest of
                Ranthambore, amidst the dense expanse of trees and abundant
                wildlife. Stretched across acres of land, and encompassed by
                lush wilderness it’s the home to the royal predators ruling the
                ruggedness of Rajasthan.
              </p>
              <p>
                During one of our morning safari’s you will experience the sight
                of wild cats prancing freely and absorbing the warmth of forest.
              </p>

              <p>
                That’s not all. You’re also in with a chance to have a close
                encounter with a Sambar, Chital, Nilgai, Chinkara, Wild Boar,
                Jackal, Hyena, Panther, Leopard, Desert Lnyx or Sloth Bear - as
                well as over 250 species of birds, marsh Crocodiles, Alligators
                and the Gangetic Dolphins.
              </p>

              <p>
                Access to Ranthambore National Park is allowed at certain
                timings which vary according to the season. The timings may vary
                a little depending on the particular season. Further details are
                available at the link below.
              </p>
              <Button
                linkto="https://nomads.transportal.co.in/"
                linktext="MAKE A BOOKING HERE"
              />
            </div>
            <table className="tg">
              <thead>
                <tr>
                  <th className="tg-0lax">Morning</th>
                  <th className="tg-0lax">Evening</th>
                  <th className="tg-0lax last b" rowSpan="2">
                    <a href="https://nomads.transportal.co.in/">More Info</a>
                  </th>
                </tr>
                <tr>
                  <td className="tg-0lax">7:00 to 10:30</td>
                  <td className="tg-0lax">14:30 to 18:30 </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div className="right">
          <img src={tiger} alt="" />
        </div>
      </RanthamboreTigers>
      <FullPage />
      <FullPage2>
        <div className="leave-no-trace">
          <div className="spinner">
            {' '}
            <img className="circle-text" src={leavenotrace} alt="" />
          </div>
          <img className="mfstar" src={leavenotracestar} alt="" />
        </div>
        <div className="text">
          <p>
            The unique location of Nahargarh makes nature an intrinsic part of
            the event’s identity. Being just a stones throw from the magnificent
            Ranthambhore National Park, a sanctuary for tigers and an abundance
            of wildlife, our respectful approach to conservation and
            sustainability informs every part of Magnetic Fields Nomads.
          </p>
          <p>
            We are counting on the support and cooperation of our guests to help
            us create a space that minimises impact in every sense and we will
            be partnering with event sustainability experts to manage and
            monitor the way in which we work with materials in the run up and
            pack down of the weekend.
          </p>
          <p>
            The ethos of this event is to create an environment rich with
            different types of content whilst being respectful to Ranthambore’s
            rich biodiversity and vast array of wildlife.
          </p>
        </div>
      </FullPage2>
    </>
  );
}
