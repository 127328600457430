import React from 'react';
import styled from 'styled-components';
import RanthamboreHero from '../components/ranthambore/ranthamborehero';
import RanthamboreSection2 from '../components/ranthambore/ranthamboreSection2';

// home images

export default function Ranthambore() {
  return (
    <>
      <RanthamboreHero />
      <RanthamboreSection2 />
    </>
  );
}
